
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Home extends Vue {


    created() {
    }

    goToLogin() {
        this.$router.push("/login");
    }

}
